<template>
  <nav
    class="navbar navbar-expand-sm navbar-dark custom-color"
    role="navigation"
  >
    <div class="container">
      <!-- Brand and toggle get grouped for better mobile display -->
      <router-link
        to="/"
        class="navbar-brand mr-auto"
      ><img
          alt="Vue logo"
          src="../assets/raffle-cropped-white.png"
          style="width:150px; height: auto"
        /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTop"
        aria-controls="navbarTop"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse"
        id="navbarTop"
        :class="{ show: isNavOpen }"
      >
        <ul class="navbar-nav mr-auto"></ul>
        <ul class="nav navbar-nav">
          <router-link
            to="/login"
            tag="li"
            v-if="!isLoggedIn"
            class="nav-item"
            active-class="active"
          >
            <a
              class="nav-link raffle-font"
              style="color:#F1EDE9; padding-top: 1rem"
            > LOGIN </a>
          </router-link>
          <li
            v-if="isLoggedIn"
            class="li-pointer nav-item"
            style="padding: 5px 10px 0px 0px"
          >
            <!-- <a
              @click="logout"
              class="nav-link"
            > {{ userEmail }}</a> -->

            <dropdown-menu
              v-model="show"
              :right="right"
              :interactive="interactive"
              style="padding-bottom:1rem"
            >
              <button class="btn btn-primary dropdown-toggle">
                Credit: Php {{ userCredits }}
              </button>
              <div slot="dropdown">
                <!-- <a
                  class="dropdown-item"
                  @click="refreshCredit"
                >Credit: Php {{ this.credit }} <i class="fa fa-refresh  fa-spin"></i></a> -->
                <a
                  class="dropdown-item"
                  href="#"
                >{{ userEmail }}</a>
                <a
                  class="dropdown-item"
                  @click="addPhone"
                >{{  userPhone }}</a>
                <a
                  class="dropdown-item"
                  @click="addLoad"
                >Add Load</a>
                <a
                  class="dropdown-item"
                  @click="logout"
                >LOGOUT</a>
              </div>
            </dropdown-menu>
          </li>
          <li>
            <router-link
              to="/cart"
              class="btn custom-header-button-color navbar-btn"
              tag="button"
            >
              <span class="material-icons md-48">shopping_cart</span> <span
                class="badge"
                style="font-size: 11pt"
              >{{ numItems }} --- ₱ {{ cartValue.toFixed(2) }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- /.container -->
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import DropdownMenu from "@innologica/vue-dropdown-menu";
import { mapActions } from "vuex";
import { ref } from "../config/firebaseConfig";
import VueFbCustomerChat from "vue-fb-customer-chat";
import Vue from "vue";

Vue.use(VueFbCustomerChat, {
  page_id: "103143258892749", //  change 'null' to your Facebook Page ID,
  theme_color: "#6667AB", // theme color in HEX
  locale: "en_US", // default 'en_US'
});

export default {
  data() {
    return {
      isNavOpen: false,
      right: true,
      show: false,
      interactive: true,
      navOpen: false,
      credit: 0,
    };
  },
  components: {
    DropdownMenu,
  },
  mounted() {
    if (this.currentUser.uid) {
      this.refreshCredit();
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "cartValue", "currentUser", "cartItemList"]),
    numItems() {
      return this.cartItemList.reduce((total, item) => {
        total += item.quantity;
        return total;
      }, 0);
    },
    userEmail() {
      return this.isLoggedIn ? this.currentUser.name : "";
    },
    userPhone() {
      return this.currentUser.phone === ""
        ? "Add Phone"
        : this.currentUser.phone;
    },
    userCredits() {
      return this.isLoggedIn ? this.currentUser.credits : "";
    },
    finalCredit() {
      var final;
      var starCountRef = firebase
        .database()
        .ref("credits/" + this.currentUser.uid);

      starCountRef.on("value", (snapshot) => {
        final = snapshot.val().credits;
      });
      return final;
    },
  },
  // watch: {
  //   currentUser: {
  //     handler(newVal, oldVal) {
  //       if (oldVal) {
  //         this.credit = 0;
  //       }
  //       this.credit = this.getCredit(newVal.uid);
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  methods: {
    ...mapActions(["getCredit"]),
    toggleNavbar() {
      this.isNavOpen = !this.isNavOpen;
    },
    refreshCredit() {
      var starCountRef = firebase
        .database()
        .ref("credits/" + this.currentUser.uid);
      starCountRef.on("value", (snapshot) => {
        this.credit = snapshot.val().credits;
      });
    },
    logout() {
      this.isLoading = true;
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.isLoading = false;
          this.$swal("Successfully signed out.");
        });
    },
    addLoad() {
      this.$swal({
        title: "Add Load via GCASH",
        html: `<input type="number" id="amount" class="swal2-input" placeholder="Amount"> <br>
        <input type="text" id="reference" class="swal2-input" placeholder="GCASH Ref. No.">`,
        confirmButtonText: "Add Load",
        focusConfirm: false,
        preConfirm: () => {
          const amount = this.$swal.getPopup().querySelector("#amount").value;
          const reference = this.$swal
            .getPopup()
            .querySelector("#reference").value;
          return { amount: amount, reference: reference };
        },
      }).then((result) => {
        // console.log("amount", result.value.amount);
        // console.log("reference", result.value.reference);
        if (
          result.value.amount < 0 ||
          result.value.amount === "" ||
          result.value.amount == null
        ) {
          console.log("hehehehe");
          this.$swal({
            icon: "error",
            title: "Please enter a valid amount you sent",
          });
        } else if (
          result.value.reference === "" ||
          result.value.reference == null
        ) {
          this.$swal({
            icon: "error",
            title: "Please enter a valid reference number from your receipt",
          });
        } else if (result.value.amount && result.value.reference) {
          this.$swal({
            icon: "success",
            title: "We will check your load asap. Thank you!",
          });
          let newTransactionKey = ref.child("load").push().key;
          var newTransaction = {};
          newTransaction[
            "/load/" + this.currentUser.uid + "/" + newTransactionKey
          ] = {
            amount: result.value.amount,
            id: this.currentUser.uid,
            date: Date.now(),
            referenceNo: result.value.reference,
            status: "pending",
          };
          return ref.update(newTransaction);
        }
      });
    },
    addPhone() {
      this.$swal({
        title: "Update Phone Number",
        html: `We will notify you for our latest raffle items. <br><input type="number" id="phone" class="swal2-input" placeholder="09171234567">`,
        confirmButtonText: "Add Phone",
        focusConfirm: false,
        preConfirm: () => {
          const phone = this.$swal.getPopup().querySelector("#phone").value;
          return { phone: phone };
        },
      }).then((result) => {
        if (result.value.phone.length < 11) {
          this.$swal({
            icon: "error",
            title: "Please enter a valid Philippine cellphone number!",
          });
        } else if (result.value.phone.length == 11) {
          this.$swal({
            icon: "success",
            title: "Phone number changed!",
          });
          firebase
            .database()
            .ref("users/" + this.currentUser.uid + "/")
            .update({
              phone: result.value.phone,
            });
        }
      });
    },
  },
};
</script>

<style scoped lange="sass">
.navbar-btn a {
  color: white;
}

.li-pointer {
  cursor: pointer;
}

.li-pointer:hover {
  cursor: pointer;
}
</style>

import firebase from 'firebase'
import "firebase/analytics";

const config = {
  apiKey: "AIzaSyCDKVUS3qjzA-9ANrecyyqXCEec4dR_XIE",
  authDomain: "raffle-com-ph.firebaseapp.com",
  databaseURL: "https://raffle-com-ph-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "raffle-com-ph",
  storageBucket: "raffle-com-ph.appspot.com",
  messagingSenderId: "909840002175",
  appId: "1:909840002175:web:3958b825aa52f5dbf0634a",
  measurementId: "G-HMZT56HEZE"
}

firebase.initializeApp(config);
firebase.analytics();

export function firebaseListener(func) {
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      // console.log("User log in success", user);
      func(true, user)
    } else {
      // console.log("User log in failed", user);
      func(false)
    }
  }, function(error) {
    console.log(error)
  });
}

export const ref = firebase.database().ref();
export const firebaseAuth = firebase.auth;

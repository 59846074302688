import Store from './components/Store.vue';
import ShoppingCart from './components/ShoppingCart.vue';
import ProductDetails from './components/ProductDetails.vue';
// import Login from './components/auth/Login.vue';
import Register from './components/auth/Register.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import Contact from './components/Contact.vue';

export const routes = [
	{path: '/', component: Store, name: 'mainpage'},
	{path: '/product/:id', component: ProductDetails, name: 'product'},
	{path: '/cart', component: ShoppingCart, name: 'shoppingcart'},
	// {path: '/login', component: Login, name: 'login', onlyGuest: true },
	{path: '/login', component: Register, name: 'register', onlyGuest: true},
	{path: '/privacy-policy', component: PrivacyPolicy, name: 'privacy'},
	{path: '/contact-us', component: Contact, name: 'contact'},

	{path: '*', redirect: '/' }
];
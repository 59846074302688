import { ref, firebaseAuth } from '../config/firebaseConfig';
import firebase from "firebase";
import pickRandom from 'pick-random';
import moment from 'moment';

export const updateCart = ({
  commit
}, {item, quantity, isAdd}) => {
  // TODO: Call service
  commit('UPDATE_CART', {item, quantity, isAdd});
  if (isAdd) {
    let message_obj = {
      message: `Add ${item.title} to cart successfully`,
      messageClass: "success",
      autoClose: true
    }
    commit('ADD_MESSAGE', message_obj);
  }
}

export const removeItemInCart = ({commit}, {item}) => {
	commit('REMOVE_CART_ITEM', {item});
}

export const registerByEmail = (_, {email, password}) => {
	return firebaseAuth().createUserWithEmailAndPassword(email, password);
}

export const registerByGoogle = () => {
	return firebaseAuth().GoogleAuthProvider();
}

export const logout = ({commit}) => {
  commit('SET_CART', []); // clear current cart
  return firebaseAuth().signOut();
}

export function loginWithEmail (_, {email, password}) {
  return firebaseAuth().signInWithEmailAndPassword(email, password);
}

export function listenToProductList({commit}) {
	return ref.child("products").on('value', (products) => {
		commit('UPDATE_PRODUCT_LIST', products.val());
	});
}

export function getShoppingCart({commit}, {uid, currentCart}) {
	if (uid) {
		return ref.child("cart/" + uid).once('value').then((cart) => {
			// console.log(cart.val());
			if (cart.val() && (!currentCart || currentCart.length == 0)) {
				commit('SET_CART', cart.val());
			}
		});
	} else {
		// console.log("User has not logged in");
	}
}

export function saveShoppingCart(_, {uid, cartItemList}) {
	return ref.child("cart/" + uid).set(cartItemList);
}

export function saveToTransaction(_, {uid, cartItem, slots}) {
	var cart = JSON.stringify(cartItem)
	var newTransaction = {}
	// var slotNumbers = []
	var newObject = {}
	let newTransactionKey = ref.child("transactions" ).push().key;
	cart = JSON.parse(cart);


		newObject.amount = cart.quantity
		newObject.totalAmount = cart.price * cart.quantity
		newObject.slots = slots.slots[0]
		newObject.date = Date.now()
		newTransaction['/transactions/' + 		moment(Date.now()).format('YYYY-MM-DD')	+ '/' + uid + '/' + cart.id+ '/' + newTransactionKey] = newObject;

		ref.update(newTransaction);
	


}

export function getSlots(uid, products) {
	let random = [];
	let final = [];

	firebase.database().ref('products/' ).once('value').then((prod) => {

		prod.forEach((child) => {
			const prodKey = child.key;
			const prodObject = child.val();
			
			if (prodObject.id === products.product) {
				firebase.database().ref('products/' + prodKey + '/slots').once('value', (snapshot) => {
					if (snapshot.val()) {
						var returnArr = [];

						snapshot.forEach(function(childSnapshot) {
							var item = childSnapshot.val();
							returnArr.push(item);
						});
					
						random = pickRandom(returnArr, {count: products.amount})
						final.push(random)

						if (random) {

							for (const color of random){
								firebase.database().ref('products/' + prodKey + '/slots' ).once('value').then((val) => {
									val.forEach((child) => {
										const userKey = child.key;
										const userObject = child.val();
										if (userObject === color) {
											removeSlot(prodKey, userKey)
											// firebase.database().ref('products/' + prodKey + '/slots/'+  userKey).remove()
										}
									});
								});
							}


						}
					}
				});
				firebase.database().ref("products/" + prodKey + "/slots").on("value", function (snapshot2) {
					if (snapshot2.exists()) {
						firebase.database().ref("products/" + prodKey + "/").update({
							quantity: snapshot2.numChildren(),
						});
					} else {
						firebase.database().ref("products/"+ prodKey).update({
							quantity: 0,
						});
					}
				});
			}
		});
		
	});
	return final

}
function removeSlot(prodKey, userKey) {
	firebase.database().ref('products/' + prodKey + '/slots/'+  userKey).remove()
}

export function addSlots(uid, products) {

	var newTransaction = {}
	products.slots.forEach((slot) => {
		for (var i = 0; i < slot.length; i++) {
			newTransaction['/slots/' + products.product + '/' + slot[i]] = products.user;
			ref.update(newTransaction);
		}
	})
	return products
	
}

export function getCredit(uid, users) {
	var starCountRef = firebase
	.database()
	.ref("credits/" + users);
  starCountRef.on("value", (snapshot) => {
	// console.log(snapshot.val().credits)
	return snapshot.val().credits;
  });
	
}


export function updateCredit(uid, amount) {

		firebase.database().ref("credits/" + amount.user).update({
			credits: amount.credits,
		});
	
	
}
<template>
  <div class="container">
    <div
      class="col-md-5"
      style="margin-right:auto; margin-left:auto; margin-bottom:20px;"
    >
      <div
        v-if="isProductLoading"
        class="text-center"
      >
        <sync-loader
          :loading="isProductLoading"
          :color="loaderColor"
          :size="loaderSize"
          class="d-inline-block"
        />
      </div>
      <div
        v-else
        class="thumbnail card custom-card"
      >
        <div class="card-header">
          <router-link
            :to="'/product/' + item.id"
            class="custom-card-font-h1"
          ><a>{{ item.title }}</a></router-link>
        </div>
        <div class="img-event intrinsic">
          <img
            :src="item.thumbnail_url"
            alt=""
            class="grow thumbnail-image card-img-top intrinsic-item p-3"
            style="border-radius:10px"
          >
        </div>

        <div class="caption-full">
          <h4 class="pull-right">Php {{ item.price.toFixed(2) }}</h4>
          <p>{{ item.description }}</p>
        </div>
        <div class="ratings">
          <h5 class="card-subtitle mb-2 remain">{{ item.quantity }} raffle ticket left</h5>

          <p
            style="margin: 20px"
            class="pull-right"
          >
            <button
              @click="addItem"
              :disabled="item.quantity === 0"
              class="btn custom-button-color"
            >
              Add to cart
            </button>
          </p>
          <div class="clearfix"></div>
        </div>
        <h5>Raffle Mechanics</h5>
        <div
          class="caption-full"
          v-html="item.mechanics"
        >
          <p>{{ item.mechanics }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
// import Multiselect from "vue-multiselect";

export default {
  components: {
    SyncLoader,
    // Multiselect,
  },
  data() {
    return {
      loaderColor: "#6667AB",
      loaderSize: "20px",
      value: null,
      options: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
    };
  },
  computed: {
    ...mapGetters(["isProductLoading", "products", "cartItemList"]),
    item() {
      let id = this.$route.params.id;
      if (this.products.length >= id) {
        let filterArr = this.products.filter((item) => {
          return item.id == id;
        });
        if (filterArr.length > 0) {
          return filterArr[0];
        }
      }
      return {};
    },
    totalNumber() {
      if (this.value === null) {
        var val = 0;
      } else {
        val = this.value.length;
      }
      return val;
    },
  },
  methods: {
    ...mapActions(["updateCart"]),
    addItem() {
      const order = {
        item: Object.assign({}, this.item),
        quantity: 1,
        isAdd: true,
      };
      if (this.cartItemList.length >= 1) {
        this.$swal({
          icon: "error",
          title: "Checkout 1 item at a time",
          text: "Something went wrong!",
        }).then((result) => {
          if (result) {
            this.$router.push("/cart");
          }
        });
      } else {
        this.updateCart(order);
        this.$router.push("/cart");
      }
      // console.log(order);
    },
  },
};
</script>

<style scoped>
.caption-full {
  padding-right: 10px;
  padding-left: 10px;
}

.ratings {
  padding-right: 10px;
  padding-left: 10px;
  color: #d17581;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

import { firebaseAuth } from '../../config/firebaseConfig';
import firebase from "firebase";

const state = {
	isLoggedIn: firebaseAuth().currentUser != null,
	user: firebaseAuth().currentUser,
}

const mutations = {
	'AUTH_STATUS_CHANGE' (state) {
		state.isLoggedIn = firebaseAuth().currentUser != null;
		state.user = firebaseAuth().currentUser;
	}
}

const actions = {

}

const getters = {

	isLoggedIn: (state) => {
		return state.isLoggedIn;
	},
	currentUser: (state) => {

		if (state && state.user) {
			var starCountRef = firebase
			.database()
			.ref("users/" + state.user.uid);
			starCountRef.on("value", (snapshot) => {      
				state.user.phoneNumber = snapshot.val().phone;
			});

			var creditsRef = firebase
			.database()
			.ref("credits/" + state.user.uid);
			creditsRef.on("value", (snapshot1) => {      
				state.user.tenantId = snapshot1.val().credits;
			});

			return {
				email: state.user.email,
				name: state.user.displayName,
				emailVerified: state.user.emailVerified,
				uid: state.user.uid,
				phone: state.user.phoneNumber,
				credits: state.user.tenantId
			}
		

		
		} else {
			return {};
		}
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}

<template>

  <div
    class="container"
    :class="{ loadingItem: isProductLoading }"
    style="margin-bottom: 20px"
  >
    <div style="width: 100%; height: auto">
      <carousel
        :per-page="1"
        :mouse-drag="false"
      >
        <slide>
          <img
            style="width: 100%; height: auto"
            src="
            https://s3.ap-southeast-1.amazonaws.com/raffle.com.ph/header.jpg"
          />
        </slide>
      </carousel>
    </div>

    <div
      class="row text-center"
      v-if="isProductLoading"
    >

      <sync-loader
        :loading="isProductLoading"
        :color="loaderColor"
        :size="loaderSize"
      ></sync-loader>
    </div>

    <div
      v-else
      class="row action-panel"
    >

      <div class="col-12">
        <div class="btn-group btn-group-sm pull-right">
          <button
            id="list"
            class="btn btn-outline-dark"
            @click.prevent="changeDisplay(true)"
          >
            <i
              class="fa fa-list"
              aria-hidden="true"
            ></i> List
          </button>
          <button
            id="grid"
            class="btn btn-outline-dark"
            @click.prevent="changeDisplay(false)"
          >
            <i
              class="fa fa-th"
              aria-hidden="true"
            ></i> Grid
          </button>
        </div>
      </div>
    </div>

    <div
      class="row"
      v-if="!isProductLoading"
    >
      <app-product-item
        v-for="prod in products"
        :item="prod"
        :key="prod.id"
        :displayList="displayList"
      ></app-product-item>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductItem from "./product/ProductItem.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import { mapActions } from "vuex";
import { Carousel, Slide } from "vue-carousel";

export default {
  data() {
    return {
      loaderColor: "#6667AB",
      loaderSize: "20px",
      displayList: false,
    };
  },
  created: function () {},
  computed: {
    ...mapGetters(["products", "isProductLoading"]),
  },
  components: {
    appProductItem: ProductItem,
    SyncLoader,
    Carousel,
    Slide,
  },
  methods: {
    ...mapActions(["updateCart"]),

    changeDisplay(isList) {
      this.displayList = isList;
    },
  },
};
</script>

<style>
.loadingItem {
  align-items: center;
  justify-content: center;
  display: flex;
}

.action-panel {
  margin-bottom: 10px;
  margin-right: 5px;
}
</style>
